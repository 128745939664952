import React from "react"
import {Link} from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'

import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import SignaturePad from "../modals/SignaturePad";
import Tax from "../modals/Tax";
import ConfirmationModal from "../modals/Confirmation";
import LocationInput from "../common/LocationInput";

export default class VATDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      hasVat: false,
      isLoading: false,
      ...this._getState(props)
    }
  }

  _getState(props){
    return {
      data: {company: props.companyMember?.company.id, tax_numbers: []}
    }
  }

  _isValid(){
    let {
      data
    } = this.state

    let error = null

    if (!data.registered_name){
      error = `Please enter the registered business name`
    }else if (!data.business_address){
      error = `Please enter the business address`
    }

    if(data.tax_numbers.length < 1){
      error = "Please provide your tax number"
    }

    if(!error){
      data.tax_numbers.map((tax, index) => {
        let taxNumber = General.toOrdinal(index+1)
        if (!tax.type){
          error = `Please select the TAX type on the ${taxNumber} tax detail`
        }else if (tax.type === "local" && !tax.country_code){
          error = `Please select the country on the ${taxNumber} tax detail`
        }else if (!tax.tax_no){
          error = `Please enter the tax no on the ${taxNumber} tax detail`
        }
      })
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _handleContinue(){
    let {
      data,
      hasVat
    } = this.state

    if(!hasVat){
      data.tax_no = null
      data.tax_numbers = []
      this.props.onIsLoading(true)
      Backend.updateCompany(data.company, data).then(() => {
        this.props.onIsLoading(false)
        this.props.onNoVat()
      }).catch(e => {
        this.props.onIsLoading(false)
        Notify.error(e.message)
      })
      return
    }

    if(!this._isValid()){
      return
    }

    this.props.onIsLoading(true)

    Backend.updateCompany(data.company, data).then(companyInfo => {
      Notify.success("TAX Details Saved")
      this.props.onVATDetailsSaved(companyInfo)
      this.props.onIsLoading(false)
    }).catch(e => {
      this.props.onIsLoading(false)
      Notify.error(e.message)
    })
  }

  _removeTaxNumber(){
    let {
      data,
      selectedIndex
    } = this.state

    data.tax_numbers.splice(selectedIndex, 1)
    this.setState({
      data,
      showConfirmationModal: false,
      selectedTax: null,
      selectedIndex: undefined
    })
  }

  _renderConfirmationModal(){
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => this._removeTaxNumber()}
        onHide={() => this.setState({ showConfirmationModal: false, selectedTax: null, selectedIndex: undefined })}
      />
    )
  }

  render() {
    let {
      data,
      hasVat,
      isLoading,
      selectedTax,
      showTaxModal,
      selectedIndex
    } = this.state

    let signatureTitle = data.signature ? "Change Signature" : "Add Signature"

    return (
      <>
        <div className="current" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="pb-10 pb-lg-15">
              <h2 className="fw-bolder text-dark">Tax Info</h2>
              <div className="text-muted fw-bold fs-6">If you need more info, please check out
                <a href="https://www.taxmatic.com/contact/" target="_blank" className="link-primary fw-bolder"> Help Page</a>.
              </div>
            </div>
            <div className="mb-10 fv-row">
              <label className="d-flex align-items-center form-label mb-3">Do You Have A Tax Number?
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="tooltip"
                  title="Sometimes also known as a VAT registration number, this is the unique number that identifies a taxable person (business) or non-taxable legal entity that is registered for VAT."
                />
              </label>

              <div className="row mb-2" data-kt-buttons="true">
                <div className="col">
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 ${hasVat ? '' : 'active'}`}
                    id="btn-no-vat"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      checked={!hasVat}
                      onChange={e => {
                        data.tax_numbers = []
                        this.setState({
                          data,
                          hasVat: !hasVat,
                        })
                      }}
                    />
                    <a className="fw-bolder fs-3">
                      No
                    </a>
                  </label>
                </div>
                <div className="col">
                  <label
                    className={`btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 ${hasVat ? 'active' : ''}`}
                    id="btn-yes-vat"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      checked={hasVat}
                      onChange={e => {
                        this.setState({hasVat: !hasVat})
                      }}
                    />
                    <a className="fw-bolder fs-3">
                      Yes
                    </a>
                  </label>
                </div>
              </div>
            </div>

            <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade show ${!hasVat ? '' : 'active'} no-vat`} role="tabpanel"/>

              <div className={`tab-pane fade show ${hasVat ? 'active' : ''} yes-vat`} role="tabpanel">
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-2">Registered Business Name</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      placeholder="Registered Business Name"
                      className="form-control form-control-solid"
                      value={data.registered_name}
                      onChange={e => {
                        data.registered_name = e.target.value
                        this.setState({data})
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-2">Business Address</label>
                  <div className="position-relative">
                    <LocationInput
                      className="form-control form-control-lg form-control-solid"
                      location={data.business_address || ""}
                      placeholder={"Business Address"}
                      onBlur={() => null}
                      allowManualInput={true}
                      onUpdated={location => {
                        data.business_address = location
                        this.setState({data})
                      }}
                    />
                  </div>
                </div>
                <div className="fv-row mb-7">
                  <h3>TAX Number</h3>
                  {
                    data.tax_numbers.map((tax, index) => (
                      <div
                        className="bg-light-success rounded border-success border border-dashed text-primary p-3 mt-7"
                        style={{position: 'relative'}}>
                        <div className="text-primary" style={{position: 'absolute', right: 10, top: 10}}>
                          <a
                            className="text-primary cursor-pointer mx-2"
                            onClick={() => {
                              this.setState({
                                showTaxModal: true,
                                selectedTax: tax,
                                selectedIndex: index
                              })
                            }}
                          >
                            <i className="la la-edit fs2"></i>
                          </a>
                          <a
                            className="text-danger cursor-pointer"
                            onClick={() => {
                              this.setState({
                                showConfirmationModal: true,
                                selectedTax: tax,
                                selectedIndex: index
                              })
                            }}
                          >
                            <i className="la la-times fs2 text-danger"></i>
                          </a>
                        </div>
                        <div>Registration Type: <b>{tax.type === "local" ? General.toTitleCase(tax.type) : tax.type.toUpperCase()}</b></div>
                        {
                          tax.type === "local" &&
                          <div>Country: <b>{tax.country_code}</b></div>
                        }
                        <div>TAX Number: <b>{tax.tax_no}</b></div>
                      </div>
                    ))
                  }

                  <a
                    className="btn btn-outline-primary mt-7"
                    onClick={() => {
                      this.setState({showTaxModal: true})
                    }}
                  >
                    + Add TAX Number
                  </a>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="d-flex flex-stack pt-15">
          <div className="mr-2"/>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-kt-stepper-action="next"
              onClick={e => this._handleContinue()}
            >
              Continue
              <span className="svg-icon svg-icon-4 ms-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        {
          showTaxModal &&
          <Tax
            show={showTaxModal}
            tax={General.clone(selectedTax)}
            onAdded={tax => {
              if(selectedIndex !== undefined){
                data.tax_numbers[selectedIndex] = tax
              }else{
                data.tax_numbers.push(tax)
              }
              this.setState({data, showTaxModal: false, selectedTax: null, selectedIndex: undefined})
            }}
            onHide={() => this.setState({showTaxModal: false, selectedTax: null, selectedIndex: undefined})}
          />
        }
        {this._renderConfirmationModal()}
      </>
    )
  }

}
