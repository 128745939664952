import React from "react"

import AccountDetails from "./components/register/AccountDetails";
import VATDetails from "./components/register/VATDetails";
import CompanyDetails from "./components/register/CompanyDetails";
import EORIDetails from "./components/register/EORIDetails";
import StoreDetails from "./components/register/StoreDetails";
import {Link} from "react-router-dom";
import General from "../utils/General";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import PackageDetail from "./components/register/PackageDetail";
import AsyncStorage from "../utils/AsyncStorage";

export default class Register extends React.Component {
  constructor(props) {
    super(props)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const state = params.get('state') || null

    const packageId = params.get('p') || null
    const integrationType = params.get('integration_type') || null

    this.state = {
      isLoading: false,
      activeTabIndex: 0,
      packageId,
      data: {
        state,
        toc: false,
        name: "",
        integration_type: integrationType,
        members: [
          {
            user: {
              first_name: "",
              last_name: "",
              email: "",
              password: "",
            }
          }
        ]
      }
    }
  }

  componentDidMount() {
    if (this.state.packageId) {
      this._getPackage()
    }

    document.getElementById("root").className = "bg-body"
    AuthManager.silentLogin().then(companyMember => {
      this.setState({companyMember})
      let onboardingStatus = companyMember.company.info.status
      if(onboardingStatus === "detail"){
        this.setState({activeTabIndex: 1})
      }else if(onboardingStatus === "tax"){
        if(companyMember.company.info.tax_no){
          this.setState({activeTabIndex: 3})
        }else{
          this.setState({activeTabIndex: 2})
        }
      }else if(onboardingStatus === "integration") {
        this.setState({activeTabIndex: 3})
      }else{
        window.location = "/"
      }
    }).then(() => {
      let hmac = General.getUrlParameter("hmac")

      if(hmac){
        let code = General.getUrlParameter("code")
        let host = General.getUrlParameter("host")
        let shop = General.getUrlParameter("shop")
        let state = General.getUrlParameter("state")
        let timestamp = General.getUrlParameter("timestamp")
        AsyncStorage.getItem('integrationType').then(integrationType => {
          let data = {
            type: integrationType,
            data: {
              code,
              hmac,
              host,
              shop,
              state,
              timestamp
            }
          }
          this.setState({activeTabIndex: 3})
          Backend.updateCompany(this.state.companyMember.company.id, data).then(response => {
            Notify.success("Shopify Connected")
            this.setState({activeTabIndex: 4})
          }).catch(e => Notify.error(e.message))
          this.props.history.replace({pathname: window.location.pathname, state: this.state})
        })
        AsyncStorage.removeItem('integrationType')
      }
    }).catch(e => {

    })

  }

  _getPackage(){
    let { packageId } = this.state
    Backend.getPackage(packageId)
      .then(packageObject => this.setState({packageObject}))
      .catch(e => Notify.error(e.message))
  }

  _getIntegrationIndex(){
    let { companyMember } = this.state
    return companyMember.company.active_integrations_no > 0 ? 4 : 3
  }

  _renderContent(){
    let { data, activeTabIndex, companyMember, packageObject } = this.state
    if(activeTabIndex == null){
      return null
    }

    switch(activeTabIndex){
      case 0: return <AccountDetails packageObject={packageObject} data={data} onIsLoading={(isLoading) => this.setState({isLoading})} onRegistered={(companyMember) => this.setState({companyMember, activeTabIndex: 1})} />
      case 1: return (
        <VATDetails
          companyMember={companyMember}
          onIsLoading={(isLoading) => this.setState({isLoading})}
          onNoVat={() => this.setState({activeTabIndex: 2})}
          onVATDetailsSaved={(companyInfo) => {
            if(companyInfo.status === "complete"){
              this.setState({activeTabIndex: 4})
            }else{
              this.setState({activeTabIndex: 3})
            }
          }}
        />
      )
      case 2: return <CompanyDetails companyMember={companyMember} onIsLoading={(isLoading) => this.setState({isLoading})} onCompanyDetailsSaved={() => this.setState({activeTabIndex: this._getIntegrationIndex()})} />
      case 3: return <StoreDetails companyMember={companyMember} onIsLoading={(isLoading) => this.setState({isLoading})} onConnected={() => this.setState({activeTabIndex: 4, isLoading: false})} onShopifyDetailsAdded={(data, total) => this.setState({data, total, activeTabIndex: this._getIntegrationIndex()})} />
      case 4: return (
        <>
          <div className="current" data-kt-stepper-element="content">
            <div className="w-100">
              <div className="pb-8 pb-lg-10">
                <h2 className="fw-bolder text-dark">You Are Done!</h2>
                <div className="text-muted fw-bold fs-6">
                  We'll contact you If we need more info, please
                  <a href="/" className="link-primary fw-bolder"> Sign In</a>.
                </div>
              </div>
            </div>
          </div>
        </>
      )
      default: return null
    }
  }

  render() {
    let {
      data,
      isLoading,
      packageId,
      packageObject,
      activeTabIndex
    } = this.state

    if(packageId && !packageObject) return null

    let className = ""

    if(isLoading){
      className = "overlay overlay-block"
    }

    let backgroundImageStyle = {backgroundImage: "url('https://images.pexels.com/photos/3057960/pexels-photo-3057960.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"}
    let backgroundColor = 'rgb(8, 47, 73, 0.6)'
    if(packageObject?.background_image){
      backgroundImageStyle = {backgroundImage: `url('${packageObject.background_image.original}')`}
      backgroundColor = 'rgb(51, 51, 51, 0.6)'
    }

    return (
      <>
        <div className={`d-flex flex-column flex-root ${isLoading && className}`}>

          <div
            className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column bgi-no-repeat bgi-size-cover first"
            id="kt_create_account_stepper" data-kt-stepper="true"
          >

            <div
              className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm bgi-no-repeat bgi-size-cover"
              style={backgroundImageStyle}
            >

              <div
                className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px h-100"
                style={{backgroundColor: backgroundColor}}
              >

                <PackageDetail
                  activeTabIndex={activeTabIndex}
                  packageObject={packageObject}
                />

              </div>

            </div>


            <div className="d-flex flex-column flex-lg-row-fluid py-10">

              <div className="d-flex flex-center flex-column flex-column-fluid" data-select2-id="select2-data-15-7d58">

                {this._renderContent()}

                <div></div>
                <div></div>
                <div></div>
                <div></div>

              </div>

            </div>

          </div>
          {
            isLoading &&
            <div className="overlay-layer bg-dark-o-10">
              <div className="spinner spinner-primary"></div>
            </div>
          }

        </div>
      </>
    )
  }
}
