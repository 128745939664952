export default class Currency {

  static format(symbol, amount, multiplier= 1){
    if(!amount) return `${symbol ? symbol : ''}0.00`
    amount = Number.parseFloat(amount) * multiplier
    amount = amount.toFixed(2)
    if(amount >= 0){
      return `${symbol ? symbol : ''}${amount ? amount.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}`
    }
    amount *= -1
    return `-${symbol ? symbol : ''}${amount ? amount.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}`
  }

  static display(symbol, amount){
    return `${symbol}${amount}`
  }
}
