import React from "react"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import StatCard from "./components/common/StatCard";
import ShopifyDetailsModal from "./components/modals/ShopifyDetails";
import Stats from "./components/store/Stats";
import IngestionsTable from "./components/tables/Ingestions";
import General from "../utils/General";
import {click} from "@testing-library/user-event/dist/click";
import ProblematicProducts from "./components/store/ProblematicProducts";
import Store from "./components/modals/Store";
import Card from "./components/store/Card";
import AsyncStorage from "../utils/AsyncStorage";

export default class Stores extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      integrations: [],
      problematicProducts: [],
      selectedIntegration: null,
      hideProblematicProducts: false
    }
  }

  componentDidMount() {
    let hmac = General.getUrlParameter("hmac")
    let success = General.getUrlParameter("success")

    if(hmac){
      let code = General.getUrlParameter("code")
      let host = General.getUrlParameter("host")
      let shop = General.getUrlParameter("shop")
      let state = General.getUrlParameter("state")
      let timestamp = General.getUrlParameter("timestamp")
      AsyncStorage.getItem('integrationType').then(integrationType => {
        let data = {
          company: AuthManager.currentUser.company.id,
          type: integrationType,
          data: {
            code,
            hmac,
            host,
            shop,
            state,
            timestamp
          }
        }
        Backend.addIntegration(data).then(selectedIntegration => {
          Notify.success("Shopify Connected")
          this.setState({selectedIntegration}, () => {
            this._getIntegrations().then(integrations => this.setState({integrations}))
          })
        }).catch(e => Notify.error(e.message))
        this.props.history.replace({pathname: window.location.pathname, state: this.state})
      })
      AsyncStorage.removeItem('integrationType')
    }else if(success){
      if(success === "1"){
        Notify.success("WooCommerce store connected!")
      }else{
        Notify.error("An error has occurred, please try again.")
      }

      this._getIntegrations().then(integrations => this.setState({integrations}, () => {
        this.setState({selectedIntegration: integrations[0]})
        this._getIntegration(integrations[0])
      }))
      this.props.history.replace({pathname: window.location.pathname, state: this.state})
    }else{
      this._getIntegrations().then(integrations => this.setState({integrations}, () => {
        if(integrations[0]){
          this.setState({selectedIntegration: integrations[0]})
          this._getIntegration(integrations[0])
        }
      }))
    }
  }

  _getIntegrations(){
    return Backend.getIntegrations('shop').then(integrations => {
      this.setState({integrationsLoaded: true})
      return integrations
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  _getIntegration(integration){
    Backend.getIntegration(integration).then(selectedIntegration => {
      this.setState({selectedIntegration})
    }).catch(e => Notify.error(e.message))
  }

  _renderIntegrationModal(){
    let{
      showIntegrationModal
    } = this.state

    return (
      <Store
        show={showIntegrationModal}
        onHide={() => this.setState({showIntegrationModal: false})}
        onStoreAdded={() => {
          this._getIntegrations().then(integrations => this.setState({integrations}, () => {
            this.setState({selectedIntegration: integrations[0]})
            this._getIntegration(integrations[0])
            this.setState({showIntegrationModal: false})
          }))
        }}
      />
    )
  }

  render() {
    let {
      stats,
      integrations,
      integrationsLoaded,
      selectedIntegration,
      hideProblematicProducts
    } = this.state

    if(!integrationsLoaded) return null

    if(selectedIntegration?.type === "amazon_file"){
      hideProblematicProducts = true
    }

    if(integrations.length < 1){
      return (
        <>
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body d-flex p-0">
              <div
                className="flex-grow-1 p-20 pb-lg-20 card-rounded h-350px flex-grow-1 bgi-no-repeat"
                style={{
                  backgroundColor: '#0ea5e9',
                  backgroundPosition: 'calc(100% + 0.5rem) bottom',
                  backgroundSize: '25% auto',
                  backgroundImage: `url(${require('../assets/media/svg/humans/custom-10.svg').default})`
                }}
              >

                <h1 className="text-white pt-10 pb-5 font-weight-bolder" style={{fontSize: 40}}>
                  Congrats!
                </h1>

                <p className="text-white pb-5" style={{fontSize: 18}}>
                  Connect your shop account<br/>
                  Let's start pulling your transactions and get you TAX compliant.<br/>
                </p>

                <button
                  className="btn btn-secondary"
                  onClick={() => this.setState({showIntegrationModal: true})}
                >
                  Connect Shop Account
                </button>

              </div>
            </div>
          </div>
          {this._renderIntegrationModal()}
        </>
      )
    }

    return (
      <>

        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">

          <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">

            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 "></div>


            <div className="d-flex align-items-center gap-2 gap-lg-3">

              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold ">
                {
                  integrations.map((integration, index) => {
                    let className = integration.id === selectedIntegration?.id ? "active" : ""
                    return (
                      <li className="nav-item">
                        <a
                          className={`nav-link text-active-primary pb-4 ${className}`}
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            this._getIntegration(integration)
                          }}
                        >
                          {integration.name}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>

              <a
                className="btn btn-light-primary btn-active-primary"
                onClick={() => this.setState({showIntegrationModal: true})}
              >
                <span className="svg-icon svg-icon-3">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                          transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                  </svg>
                </span>
                Add Store
              </a>
            </div>

          </div>

        </div>

        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade show active" id="store_1" role="tabpanel">
            <div className="row">
              <div className="col-xl-6">
                <Card
                  integration={selectedIntegration}
                />
              </div>
              <div className="col-xl-6">
                <Stats
                  integration={selectedIntegration}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-xl-12">
                <IngestionsTable
                  key={selectedIntegration?.id}
                  integration={selectedIntegration}
                />
              </div>
            </div>
          </div>
        </div>
        {this._renderIntegrationModal()}
      </>
    )
  }

}
