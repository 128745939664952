import React from "react"
import {Link} from "react-router-dom";

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import ShopifyHelp from "../modals/ShopifyHelp";
import Backend from "../../../utils/Backend";
import Select from "../common/Select";
import CountrySelect from "../common/CountrySelect";
import SmartList from "../common/SmartList";
import General from "../../../utils/General";
import AsyncStorage from "../../../utils/AsyncStorage";

export default class StoreDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
      isLoading: false
    }
  }

  _getState(props){
    return {
      placeholder: "",
      integrationData: {
        company: props.companyMember?.company.id,
        redirect_url: `${window.location.origin}${window.location.pathname}`,
        type: "",
        data: {
          return_url: `${window.location.origin}${window.location.pathname}`,
        }
      }
    }
  }

  _connectStore(){
    let {
      integrationData
    } = this.state

    let title = ""
    if(integrationData.data.ship_from_evaluation_method === "order_status"){
      title = "order status"
    }else if(integrationData.data.ship_from_evaluation_method === "note"){
      title = "note"
    }

    if(!integrationData.data.shop){
      Notify.error("Please enter your shop url")
      return
    }

    if(integrationData.type === "shopify"){
      if(!integrationData.data.shop.includes('.myshopify.com')){
        Notify.error("Please enter a valid Shopify shop url")
        return
      }

      let shopifyUrlData = integrationData.data.shop.split('.')

      if(shopifyUrlData[0].length < 1){
        Notify.error("Please enter a valid Shopify shop url")
        return
      }
    }

    if(integrationData.type === "shopline"){
      if(!integrationData.data.shop.includes('.myshopline.com')){
        Notify.error("Please enter a valid Shopline shop url")
        return
      }

      let shopifyUrlData = integrationData.data.shop.split('.')

      if(shopifyUrlData[0].length < 1){
        Notify.error("Please enter a valid Shopline shop url")
        return
      }
    }

    if(!integrationData.data.shop.startsWith('http')){
      integrationData.data.shop = 'https://' + integrationData.data.shop
    }

    if(integrationData.type === "woocommerce"){
      if(!integrationData.data.ship_from_country_code){
        Notify.error("Please select the country where your warehouse is located")
        return
      }

      if(!integrationData.multiple_warhouses){
        Notify.error("Please indicate if you ship from multiple locations")
        return
      }

      if(integrationData.multiple_warhouses === "yes"){
        if(!integrationData.data.ship_from_evaluation_method){
          Notify.error("Please select how you identify warehouse location")
          return
        }

        if(integrationData.data.warehouses.length < 1){
          Notify.error("Please add your warehouse locations")
          return
        }

        let error = null

        integrationData.data.warehouses.map((warehouse, index) => {
          let warehouseNumber = General.toOrdinal(index+1)
          if (!warehouse.comparison_value){
            error = `Please enter the ${title} on the ${warehouseNumber} warehouse`
          }else if(!warehouse.country_code){
            error = `Please select the ${warehouseNumber} warehouse location`
          }
        })

        if(error){
          Notify.error(error)
          return;
        }

      }

      integrationData.data.return_url = `${window.location.origin}/stores`
    }

    this.props.onIsLoading(true)

    Backend.updateCompany(integrationData.company, integrationData).then(response => {
      if(integrationData.type !== "amazon_file"){
        window.location = response.redirect_url
      }else{
        this.props.onConnected()
      }
    }).catch(e => {
      this.props.onIsLoading(false)
      Notify.error(e.message)
    })
  }

  _renderWarehousePicker(location, index){
    let {
      integrationData
    } = this.state

    let placeholder = ""
    if(integrationData.data.ship_from_evaluation_method === "order_status"){
      placeholder = "Order Status"
    }else if(integrationData.data.ship_from_evaluation_method === "note"){
      placeholder = "Note"
    }

    return (
      <>
        <label className="form-label fw-bolder text-dark fs-6 p-0">{placeholder}</label>
        <input
          className="form-control form-control-lg form-control-solid"
          placeholder={placeholder}
          value={integrationData.data.warehouses[index].comparison_value}
          onChange={e => {
            integrationData.data.warehouses[index].comparison_value = e.target.value
            this.setState({integrationData})
          }}
        />
        <label className="form-label mt-5 fw-bolder text-dark fs-6 p-0">Location</label>
        <CountrySelect
          value={integrationData.data.warehouses[index].country_code}
          onSelected={country => {
            integrationData.data.warehouses[index].country_code = country.value
            this.setState({ integrationData })
          }}
        />
      </>
    )
  }

  render() {
    let {
      placeholder,
      integrationData,
      showShopifyHelp,
      isLoading
    } = this.state

    let title = "Shop"

    if(integrationData.type === "shopify"){
      title = "Shopify"
    }else if(integrationData.type === "woocommerce"){
      title = "WooCommerce"
    }else if(integrationData.type === "amazon_file"){
      title = "Amazon"
    }else if(integrationData.type === "shopline"){
      title = "Shopline"
    }

    return (
      <>
        <div className="current w-lg-600px" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="pt-lg-20 pt-20 pb-5 pb-lg-5">
              <h2 className="fw-bolder text-dark">
                Connect Your Store
              </h2>
              <div className="text-muted fw-bold fs-6">
                Connect your store account.
              </div>
            </div>

            <div className="w-100 text-center">

              <div className="fv-row mt-5 mb-10 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                <label className="form-label fw-bolder text-dark fs-6 mb-2">Store</label>
                <Select
                  value={integrationData.type}
                  disabled={!!integrationData.id}
                  options={[
                    {
                      label: "Shopify",
                      value: "shopify"
                    },
                    {
                      label: "Shopline",
                      value: "shopline"
                    },
                    {
                      label: "WooCommerce",
                      value: "woocommerce"
                    },
                    {
                      label: "Manual Upload",
                      value: "amazon_file"
                    }
                  ]}
                  placeholder={"Store"}
                  getOptionLabel={type => type.label}
                  getOptionValue={type => type.value}
                  className="form-control form-control-solid h-auto c-selectbox"
                  classNamePrefix="filter-select"
                  onSelected={type => {
                    integrationData.type = type.value
                    if(type.value === "woocommerce") {
                      integrationData.data.ship_from_country_code = ""
                    }else {
                      delete integrationData.data.ship_from_country_code
                    }
                    AsyncStorage.setItem('integrationType', type.value)
                    this.setState({integrationData})
                  }}
                />
              </div>



              {
                integrationData.type !== "" &&
                <>
                  <div className="fv-row mt-5 mb-10 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                    <label className="form-label fw-bolder text-dark fs-6">{title} Shop URL</label>
                    {
                      integrationData.type === "shopify" || integrationData.type === "shopline" &&
                      <div className="text-muted fw-bold pb-2 fs-6">
                        Help finding your store url. <a href="#"
                           className="text-primary fw-bolder"
                           onClick={() => {
                             this.setState({showShopifyHelp: true})
                           }}
                        >
                          Help Page
                        </a>
                      </div>
                    }
                    <div className="form-group">
                      <div className="input-group with-prepend">
                        <div className="input-group-prepend" style={{marginRight: -4}}>
                      <span className="form-control form-control-lg form-control-solid">
                        <span>https://</span>
                      </span>
                        </div>
                        <input
                          className="form-control form-control-lg form-control-solid"
                          placeholder={placeholder}
                          value={integrationData.data.shopUrl}
                          onChange={e => {
                            integrationData.data.shop = e.target.value.replace(/ /g,'')
                            integrationData.data.shopUrl = e.target.value.replace(/ /g,'')
                            if(integrationData.type === "shopline") {
                              integrationData.data.handle = e.target.value
                            }
                            this.setState({integrationData})
                          }}
                        />
                      </div>
                    </div>
                    {
                      integrationData.type === "shopify" || integrationData.type === "shopline" &&
                      <div className="text-muted text-start mb-4">
                        If you have connected your {title} store to a custom domain name, you will still need<br/>
                        to use your original {title} store URL provided in order to connect with Taxmatic.
                      </div>
                    }

                  </div>

                  {
                    integrationData.type === "woocommerce" &&
                    <>
                      <div className="fv-row mt-5 mb-10 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                        <label className="form-label fw-bolder text-dark fs-6">Warehouse Location</label>
                        <div className="form-group">
                          <CountrySelect
                            value={integrationData.data.ship_from_country_code}
                            onSelected={country => {
                              integrationData.data.ship_from_country_code = country.value
                              this.setState({ integrationData })
                            }}
                          />
                        </div>
                      </div>
                      <div className="fv-row mt-5 mb-10 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                        <label className="form-label fw-bolder text-dark fs-6">Do you ship from multiple warehouses?</label>
                        <div className="form-group">
                          <Select
                            value={integrationData.type}
                            disabled={!!integrationData.id}
                            options={[
                              {
                                label: "Yes",
                                value: "yes"
                              },
                              {
                                label: "No",
                                value: "no"
                              }
                            ]}
                            placeholder={"Multiple Warehouses"}
                            getOptionLabel={type => type.label}
                            getOptionValue={type => type.value}
                            className="form-control form-control-solid h-auto c-selectbox"
                            classNamePrefix="filter-select"
                            onSelected={type => {
                              integrationData.multiple_warhouses = type.value
                              if(type.value === "yes") {
                                integrationData.data.warehouses = []
                              }else {
                                delete integrationData.data.warehouses
                              }
                              this.setState({integrationData})
                            }}
                          />
                        </div>
                      </div>
                      {
                        integrationData.multiple_warhouses === "yes" &&
                        <>
                          <div className="fv-row mt-5 mb-10 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                            <label className="form-label fw-bolder text-dark fs-6">How do you distinguish the ship from location on each order?</label>
                            <div className="form-group">
                              <Select
                                value={integrationData.data.ship_from_evaluation_method}
                                disabled={!!integrationData.id}
                                options={[
                                  {
                                    label: "Order Status",
                                    value: "order_status"
                                  },
                                  {
                                    label: "Via Note / Plugin",
                                    value: "note"
                                  }
                                ]}
                                placeholder={"Identifier"}
                                getOptionLabel={type => type.label}
                                getOptionValue={type => type.value}
                                className="form-control form-control-solid h-auto c-selectbox"
                                classNamePrefix="filter-select"
                                onSelected={type => {
                                  integrationData.data.ship_from_evaluation_method = type.value
                                  this.setState({integrationData})
                                }}
                              />
                            </div>
                          </div>
                          <div className="fv-row mb-10 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                            <SmartList
                              items={integrationData.data.warehouses}
                              itemName={"Warehouse"}
                              showItemName={true}
                              renderItem={(location, index) => this._renderWarehousePicker(location, index)}
                              onAddItemPressed={() => {
                                integrationData.data.warehouses.push({})
                                this.setState({integrationData})
                              }}
                              onUpdated={(items) => {
                                integrationData.data.warehouses = items
                                this.setState({integrationData})
                              }}
                            />
                          </div>
                        </>
                      }

                    </>
                  }

                </>
              }

              {
                integrationData.type !== "" &&
                <a
                  className="btn btn-connect-store mt-10"
                  onClick={() => this._connectStore()}
                >
                  Connect
                </a>
              }

            </div>

          </div>
        </div>
        {
          showShopifyHelp &&
          <ShopifyHelp
            show={showShopifyHelp}
            title={title}
            onHide={() => this.setState({showShopifyHelp: false})}
          />
        }
      </>
    )
  }

}
